import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import BookIcon from '@mui/icons-material/Book'
import SportsTennisIcon from '@mui/icons-material/SportsTennis'
import AddIcon from '@mui/icons-material/Add'
import WorkIcon from '@mui/icons-material/Work'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { Link } from 'react-router-dom'

export const mainListItems = (
  <>
    <Link to="/admin/blog">
      <ListItemButton>
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Blog List" />
      </ListItemButton>
    </Link>
    <Link to="/admin/blog/add">
      <ListItemButton>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Add Blog" />
      </ListItemButton>
    </Link>
  </>
)

export const secondaryListItems = (
  <>
    <Link to="/admin/skill">
      <ListItemButton>
        <ListItemIcon>
          <SportsTennisIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Skill List" />
      </ListItemButton>
    </Link>
    <Link to="/admin/skill/add">
      <ListItemButton>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Add Skill" />
      </ListItemButton>
    </Link>
  </>
)

export const thirdListItems = (
  <>
    <Link to="/admin/portfolio">
      <ListItemButton>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Portfolio List" />
      </ListItemButton>
    </Link>
    <Link to="/admin/portfolio/add">
      <ListItemButton>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Add Portfolio" />
      </ListItemButton>
    </Link>
  </>
)

export const fourthListItems = (
  <>
    <Link to="/admin/blogTag">
      <ListItemButton>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Blog tag List" />
      </ListItemButton>
    </Link>
    <Link to="/admin/blogTag/add">
      <ListItemButton>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Add blog tag" />
      </ListItemButton>
    </Link>
  </>
)
