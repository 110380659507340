import { Box, FormControl, TextField, Paper, Button } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/system'
import { baseURL, config } from '../../api/api'
import axios from 'axios'

export const PortfolioAddForm = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [name, setName] = React.useState('')
  const [url, setUrl] = React.useState('')
  const [image, setImage] = React.useState('')
  const [description, setDescription] = React.useState('')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleUrlChange = (event) => {
    setUrl(event.target.value)
  }

  const handleImageChange = (event) => {
    setImage(event.target.files[0])
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (name && url && image && description) {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('url', url)
      formData.append('image', image)
      formData.append('description', description)

      axios
        .post(`${baseURL}/portfolio`, formData, config)
        .then((res) => {
          console.log(res)
          navigate('/admin/portfolio')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '90%', mx: 'auto', mt: '30px', p: '10px' }}
        elevation={24}>
        <h2 style={{ textAlign: 'center' }}>Add a portfolio</h2>
        <FormControl style={{ display: 'flex', gap: '20px', padding: '10px' }}>
          <TextField
            required
            fullWidth
            label="Name"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
          <TextField
            required
            id="url"
            label="URL"
            multiline
            value={url}
            onChange={handleUrlChange}
          />
          <Button
            variant="contained"
            component="label"
            color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}>
            Upload image
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </Button>
          <TextField
            required
            id="description"
            label="Description"
            multiline
            row={6}
            value={description}
            onChange={handleDescriptionChange}
          />
          <div>
            <Link to="/admin/portfolio">
              <Button
                sx={{ width: '5%', mr: '20px' }}
                variant="outlined"
                color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
                back
              </Button>
            </Link>
            <Button
              sx={{ width: '5%', display: 'inline' }}
              variant="outlined"
              onClick={handleSubmit}
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              add
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Box>
  )
}
