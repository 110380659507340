import { Box, FormControl, TextField, Paper, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/system'
import { baseURL, config } from '../../api/api'
import axios from 'axios'
import Loading from '../Loading/Loading'

export const PortfolioEditForm = () => {
  const theme = useTheme()

  const { portfolioId } = useParams()
  const navigate = useNavigate()

  const [name, setName] = React.useState('')
  const [url, setUrl] = React.useState('')
  const [image, setImage] = React.useState('')
  const [description, setDescription] = React.useState('')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleUrlChange = (event) => {
    setUrl(event.target.value)
  }

  const handleImageChange = (event) => {
    setImage(event.target.value)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const fetchPortfolio = () => {
    axios
      .get(`${baseURL}/portfolio/${portfolioId}`)
      .then((res) => {
        setName(res.data.name)
        setUrl(res.data.url)
        setImage(res.data.image)
        setDescription(res.data.description)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchPortfolio()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    axios
      .put(
        `${baseURL}/portfolio/${portfolioId}`,
        { name, url, image, description },
        config
      )
      .then((res) => {
        navigate('/admin/portfolio')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (!name) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '90%', mx: 'auto', mt: '30px', p: '10px' }}
        elevation={24}>
        <h2 style={{ textAlign: 'center' }}>Edit a portfolio</h2>
        <FormControl style={{ display: 'flex', gap: '20px', padding: '10px' }}>
          <TextField
            required
            fullWidth
            label="Name"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
          <TextField
            required
            id="url"
            label="URL"
            multiline
            value={url}
            onChange={handleUrlChange}
          />
          <TextField
            required
            id="image"
            label="Image URL"
            multiline
            value={image}
            onChange={handleImageChange}
          />
          <TextField
            required
            id="description"
            label="Description"
            multiline
            row={6}
            value={description}
            onChange={handleDescriptionChange}
          />
          <div>
            <Link to="/admin/portfolio">
              <Button
                sx={{ width: '5%', mr: '20px' }}
                variant="outlined"
                color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
                back
              </Button>
            </Link>
            <Button
              sx={{ width: '5%', display: 'inline' }}
              variant="outlined"
              onClick={handleSubmit}
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Edit
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Box>
  )
}
