import {
  Box,
  FormControl,
  TextField,
  Paper,
  Button,
  Select,
  MenuItem,
} from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/system'
import axios from 'axios'
import { baseURL, config } from '../../api/api'

export const SkillAddForm = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [name, setName] = React.useState('')
  const [image, setImage] = React.useState('')
  const [area, setArea] = React.useState('')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleImageChange = (event) => {
    setImage(event.target.files[0])
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (name && image && area) {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('image', image)
      formData.append('area', area)
      axios
        .post(`${baseURL}/skill`, formData, config)
        .then((res) => {
          navigate('/admin/skill')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '90%', mx: 'auto', mt: '30px', p: '10px' }}
        elevation={24}>
        <h2 style={{ textAlign: 'center' }}>Add a Skill</h2>
        <FormControl style={{ display: 'flex', gap: '20px', padding: '10px' }}>
          <TextField
            required
            fullWidth
            label="Name"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
          <Button
            variant="contained"
            component="label"
            color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}>
            Upload image
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </Button>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={area}
            label="area"
            onChange={(event) => setArea(event.target.value)}>
            <MenuItem value={'front end'}>Front End</MenuItem>
            <MenuItem value={'back end'}>Back End</MenuItem>
            <MenuItem value={'database'}>Database</MenuItem>
            <MenuItem value={'version control'}>Version Control</MenuItem>
            <MenuItem value={'deployment'}>Deployment</MenuItem>
          </Select>
          <div>
            <Link to="/admin/skill">
              <Button
                sx={{ width: '5%', mr: '20px' }}
                variant="outlined"
                color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
                back
              </Button>
            </Link>
            <Button
              sx={{ width: '5%', display: 'inline' }}
              variant="outlined"
              onClick={handleSubmit}
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Add
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Box>
  )
}
