import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { baseURL, config } from '../../api/api'
import Loading from '../Loading/Loading'

export default function SkillTable() {
  const [skillList, setSkillList] = React.useState()
  const skillArea = [
    'front end',
    'back end',
    'database',
    'version control',
    'deployment',
  ]

  const fetchSkillList = () => {
    axios
      .get(`${baseURL}/skill`)
      .then((res) => {
        setSkillList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchSkillList()
  }, [])

  const handleDelete = (skillId) => {
    axios
      .delete(`${baseURL}/skill/${skillId}`, config)
      .then((res) => {
        setSkillList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (!skillList) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return skillArea.map((skill) => (
    <Paper
      key={skill}
      elevation={24}
      sx={{ width: '90%', mx: 'auto', mt: '50px' }}>
      <TableContainer>
        <h3 style={{ paddingLeft: '20px', textTransform: 'capitalize' }}>
          {skill}
        </h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillList
              .filter((i) => i.area === skill)
              .map((i) => (
                <TableRow key={i._id}>
                  <TableCell align="left">{i.name}</TableCell>
                  <TableCell align="left">
                    <img src={i.image} alt={i.name} style={{ width: '30px' }} />
                  </TableCell>
                  <TableCell align="left">
                    <Link to={'edit/' + i._id} style={{ margin: '5px' }}>
                      edit
                    </Link>
                    <Link
                      to={''}
                      style={{ margin: '5px' }}
                      onClick={() => handleDelete(i._id)}>
                      delete
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ))
}
