import SearchIcon from '@mui/icons-material/Search'
import { Pagination, Paper, TextField } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import React from 'react'
import { baseURL } from '../../api/api'
import Loading from '../../components/Loading/Loading'
import BlogCard from '../../components/BlogCard/BlogCard'
import { Helmet } from 'react-helmet'

function BlogPage() {
  const [search, setSearch] = React.useState('')
  const [blogList, setBlogList] = React.useState()
  const [pageNumber, setPageNumber] = React.useState(1)
  const [pageCount, setPageCount] = React.useState()
  const [blogTagList, setBlogTagList] = React.useState()
  const [selectedTag, setSelectedTag] = React.useState('all')
  const blogPerPage = 3

  const fetchBlog = () => {
    axios
      .get(`${baseURL}/blog`)
      .then((res) => {
        setBlogList(res.data)
        setPageCount(Math.ceil(res.data.length / blogPerPage))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const fetchBlogTagList = () => {
    axios
      .get(`${baseURL}/blogTag`)
      .then((res) => {
        setBlogTagList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchBlog()
    fetchBlogTagList()
  }, [])

  if (!blogList || !blogTagList) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return (
    <Paper elevation={24} style={{ borderRadius: 0, minHeight: '90vh' }}>
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Junyou Lin Blog Page" />
      </Helmet>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '0',
          paddingTop: '30px',
          fontSize: '30px',
          textTransform: 'uppercase',
        }}>
        Blog
      </h2>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          width: '300px',
          margin: 'auto',
        }}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id="input-with-sx"
          label="Find a blog"
          variant="standard"
          sx={{ width: '300px' }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <ul
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          width: '300px',
          margin: '10px auto',
          gap: '20px',
          listStyle: 'none',
          fontSize: '0.8rem',
        }}>
        <li
          style={{
            cursor: 'pointer',
            textDecoration: selectedTag === 'all' ? 'underline' : 'none',
          }}
          onClick={() => setSelectedTag('all')}>
          #ALL
        </li>
        {blogTagList.map((tag) => (
          <li
            key={tag._id}
            style={{
              cursor: 'pointer',
              textTransform: 'uppercase',
              textDecoration: selectedTag === tag.name ? 'underline' : 'none',
            }}
            onClick={() => setSelectedTag(tag.name)}>
            #{tag.name}
          </li>
        ))}
      </ul>
      <div
        style={{
          marginTop: '30px',
          paddingBottom: '30px',
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
        }}>
        <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          {blogList
            .filter((blog) => {
              if (selectedTag === 'all') {
                return true
              }
              return blog.blogTag.map((tag) => tag.name).includes(selectedTag)
            })
            .filter((blog) =>
              blog.title.toUpperCase().includes(search.toUpperCase())
            )
            .slice((pageNumber - 1) * blogPerPage, pageNumber * blogPerPage)
            .map(({ _id, title, content, createdAt, blogTag }) => (
              <BlogCard
                key={_id}
                id={_id}
                createdAt={createdAt}
                title={title}
                content={content}
                tag={blogTag}
              />
            ))}
          <Pagination
            style={{ justifyContent: 'center' }}
            count={pageCount}
            page={pageNumber}
            onChange={(event, value) => setPageNumber(value)}
          />
        </div>
      </div>
    </Paper>
  )
}

export default BlogPage
