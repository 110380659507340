import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import BlogPage from './pages/BlogPage/BlogPage'
import HomePage from './pages/Homepage/HomePage'
import PortfolioPage from './pages/PortfolioPage/PortfolioPage'
import SkillPage from './pages/SkillPage/SkillPage'
import PostPage from './pages/PostPage/PostPage'
import NotFound from './components/NotFound/NotFound'
import LoginPage from './pages/LoginPage/LoginPage'
import AdminLayout from './layout/AdminLayout'
import Frontend from './routes/Frontend'
import BlogTable from './components/Table/BlogTable'
import SkillTable from './components/Table/SkillTable'
import { BlogAddForm } from './components/Form/BlogAddForm'
import { SkillAddForm } from './components/Form/SkillAddForm'
import PortfolioTable from './components/Table/PortfolioTable'
import { PortfolioEditForm } from './components/Form/PortfolioEditForm'
import { PortfolioAddForm } from './components/Form/PortfolioAddForm'
import BlogTagTable from './components/Table/BLogTagTable'
import { BlogTagAddForm } from './components/Form/BlogTagAddForm'
import { BlogTagEditForm } from './components/Form/BlogTagEditForm'
import { BlogEditForm } from './components/Form/BlogEditForm'
import './App.css'
import { SkillEditForm } from './components/Form/SkillEditForm'

export default function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Frontend />}>
            <Route index element={<HomePage />} />
            <Route path="/skill" element={<SkillPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:postId" element={<PostPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<BlogTable />} />
            <Route path="/admin/blog" element={<BlogTable />} />
            <Route path="/admin/blog/view/:postId" element={<PostPage />} />
            <Route path="/admin/blog/add" element={<BlogAddForm />} />
            <Route path="/admin/blog/edit/:postId" element={<BlogEditForm />} />
            <Route path="/admin/skill" element={<SkillTable />} />
            <Route path="/admin/skill/add" element={<SkillAddForm />} />
            <Route
              path="/admin/skill/edit/:skillId"
              element={<SkillEditForm />}
            />
            <Route path="/admin/portfolio" element={<PortfolioTable />} />
            <Route path="/admin/portfolio/add" element={<PortfolioAddForm />} />
            <Route
              path="/admin/portfolio/edit/:portfolioId"
              element={<PortfolioEditForm />}
            />
            <Route path="/admin/blogTag" element={<BlogTagTable />} />
            <Route path="/admin/blogTag/add" element={<BlogTagAddForm />} />
            <Route
              path="/admin/blogTag/edit/:blogTagId"
              element={<BlogTagEditForm />}
            />
          </Route>
        </Routes>
      </div>
    </>
  )
}
