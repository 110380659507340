import { Button, Paper } from '@mui/material'
import React from 'react'
import './Homepage.scss'
import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import EmailIcon from '@mui/icons-material/Email'
import MovingComponent from 'react-moving-text'
import { Helmet } from 'react-helmet'
import DownloadIcon from '@mui/icons-material/Download'

function HomePage() {
  return (
    <Paper elevation={24} style={{ borderRadius: 0, minHeight: '90vh' }}>
      <Helmet>
        <title>Junyou Lin</title>
        <meta
          name="description"
          content="Hi, there! I am Junyou Lin, A passionate full-stack web developer based in Melbourne, Australia"
        />
      </Helmet>
      <section className="homePage">
        <div className="personalInfo">
          <p
            style={{
              fontSize: '30px',
              fontWeight: 'bold',
              margin: '0px',
            }}>
            Hi, there!, I'm
          </p>
          <MovingComponent
            style={{ fontSize: '60px', fontWeight: 'bold' }}
            type="bounce"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="3"
            fillMode="none">
            Junyou Lin
          </MovingComponent>
          <h1>
            A passionate full-stack web developer based in Melbourne, Australia
          </h1>
          <div className="iconContainer">
            <a
              href="https://github.com/Junyou-Lin"
              target="_blank"
              rel="noopener noreferrer">
              <GitHubIcon sx={{ fontSize: 30 }} />
            </a>
            <a
              href="https://www.linkedin.com/in/junyou-lin/"
              target="_blank"
              rel="noopener noreferrer">
              <LinkedInIcon sx={{ fontSize: 30 }} />
            </a>
            <a href="mailto:junyoulin00@gmail.com">
              <EmailIcon sx={{ fontSize: 30 }} />
            </a>
          </div>
          <Button
            variant="contained"
            href="https://junyou-lin-website-pictures.s3.us-east-1.amazonaws.com/1658123969506"
            sx={{ width: '150px', my: '30px' }}
            endIcon={<DownloadIcon />}>
            Resume.pdf
          </Button>
        </div>
        <div className="coderImgContainer">
          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_iorpbol0.json"
            background="transparent"
            style={{ width: '80%', height: '80%' }}
            speed="1"
            loop
            autoplay></lottie-player>
        </div>
      </section>
    </Paper>
  )
}

export default HomePage
