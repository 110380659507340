import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'
import { useTheme } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../../api/api'

export default function SignIn() {
  const theme = useTheme()

  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    axios
      .post(`${baseURL}/users/login`, {
        email: data.get('email'),
        password: data.get('password'),
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        navigate('/admin')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Paper
      elevation={12}
      style={{ borderRadius: 0, minHeight: '80vh', paddingTop: '10vh' }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Avatar
        sx={{
          m: 1,
          bgcolor: `${theme.palette.mode === 'dark' ? 'primary' : 'secondary'}`,
        }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, p: 2 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
      </Box>
    </Paper>
  )
}
