import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Button } from '@mui/material'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Junyou Lin
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '100px',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}>
      <Copyright />
      <Button
        sx={{ position: 'fixed', bottom: '10px', right: '10px' }}
        variant="contained"
        href="https://donate.stripe.com/4gw0287N8czG0CY000"
        endIcon={
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_Dz3Jb8.json"
            background="transparent"
            style={{ width: '100px' }}
            speed="1"
            loop
            autoplay></lottie-player>
        }>
        Buy me a coffee
      </Button>
    </Box>
  )
}
