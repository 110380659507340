import {
  Box,
  FormControl,
  TextField,
  Paper,
  Button,
  Autocomplete,
} from '@mui/material'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/system'
import axios from 'axios'
import { baseURL, config } from '../../api/api'
import Loading from '../Loading/Loading'

export const BlogEditForm = () => {
  const theme = useTheme()
  const { postId } = useParams()

  const [blogTagList, setBlogTagList] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [content, setContent] = React.useState('')
  const [blogTagId, setBlogTagId] = React.useState('')
  const navigate = useNavigate()

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const handleContentChange = (event) => {
    setContent(event.target.value)
  }

  const fetchBlogTagList = () => {
    axios
      .get(`${baseURL}/blogTag`)
      .then((res) => {
        setBlogTagList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const fetchBlog = () => {
    axios
      .get(`${baseURL}/blog/${postId}`)
      .then((res) => {
        setTitle(res.data.title)
        setContent(res.data.content)
        setBlogTagId(res.data.blogTag)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchBlog()
    fetchBlogTagList()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (title && content && blogTagId) {
      axios
        .put(
          `${baseURL}/blog/${postId}`,
          { title, content, blogTag: blogTagId },
          config
        )
        .then((res) => {
          navigate('/admin/blog')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  if (!title || !blogTagList || !blogTagId) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }
  console.log(blogTagList)

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '90%', mx: 'auto', mt: '30px', p: '10px' }}
        elevation={24}>
        <h2 style={{ textAlign: 'center' }}>Add a blog</h2>
        <FormControl style={{ display: 'flex', gap: '20px', padding: '10px' }}>
          <TextField
            required
            fullWidth
            label="title"
            id="title"
            value={title}
            onChange={handleTitleChange}
          />
          <TextField
            required
            id="content"
            label="Content"
            multiline
            rows={20}
            value={content}
            onChange={handleContentChange}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={blogTagList}
            defaultValue={blogTagId}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setBlogTagId(value.map((item) => item))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Blog tag"
                placeholder="Add blog tag"
              />
            )}
          />
          <div>
            <Link to="/admin/blog">
              <Button
                sx={{ width: '5%', mr: '20px' }}
                variant="outlined"
                color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
                back
              </Button>
            </Link>
            <Button
              sx={{ width: '5%', display: 'inline' }}
              variant="outlined"
              onClick={handleSubmit}
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Edit
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Box>
  )
}
