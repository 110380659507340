import { Box, FormControl, TextField, Paper, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/system'
import { baseURL, config } from '../../api/api'
import axios from 'axios'
import Loading from '../Loading/Loading'

export const BlogTagEditForm = () => {
  const theme = useTheme()

  const { blogTagId } = useParams()
  const navigate = useNavigate()

  const [name, setName] = React.useState('')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const fetchBlogTag = () => {
    axios
      .get(`${baseURL}/blogTag/${blogTagId}`)
      .then((res) => {
        setName(res.data.name)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchBlogTag()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    axios
      .put(`${baseURL}/blogTag/${blogTagId}`, { name }, config)
      .then((res) => {
        navigate('/admin/blogTag')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (!name) {
    return <Loading />
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '90%', mx: 'auto', mt: '30px', p: '10px' }}
        elevation={24}>
        <h2 style={{ textAlign: 'center' }}>Edit a blog tag</h2>
        <FormControl style={{ display: 'flex', gap: '20px', padding: '10px' }}>
          <TextField
            required
            fullWidth
            label="Name"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
          <div>
            <Link to="/admin/blogTag">
              <Button
                sx={{ width: '5%', mr: '20px' }}
                variant="outlined"
                color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
                back
              </Button>
            </Link>
            <Button
              sx={{ width: '5%', display: 'inline' }}
              variant="outlined"
              onClick={handleSubmit}
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Edit
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Box>
  )
}
