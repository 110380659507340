export default function Loading() {
  return (
    <div style={{ width: '50%', height: '90vh', margin: 'auto' }}>
      <lottie-player
        src="https://assets4.lottiefiles.com/private_files/lf30_DGRf6G.json"
        background="transparent"
        speed="1"
        loop
        autoplay></lottie-player>
    </div>
  )
}
