import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect } from 'react'
import './PortfolioPage.scss'
import MovingComponent from 'react-moving-text'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Loading from '../../components/Loading/Loading'
import { baseURL } from '../../api/api'

function PortfolioPage() {
  const theme = useTheme()

  const [portfolioList, setPortfolioList] = React.useState()

  const fetchPortfolioList = () => {
    axios
      .get(`${baseURL}/portfolio`)
      .then((res) => {
        setPortfolioList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchPortfolioList()
  }, [])

  if (!portfolioList) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return (
    <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
      <Helmet>
        <title>Portfolio</title>
        <meta
          name="description"
          content={portfolioList.map((portfolio) => portfolio.name).join(', ')}
        />
      </Helmet>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '0',
          paddingTop: '30px',
          fontSize: '30px',
          textTransform: 'uppercase',
        }}>
        Portfolio
      </h2>
      <div className="portfolioCardContainer">
        {portfolioList.map(({ name, image, url, description }) => {
          return (
            <MovingComponent
              key={name}
              type="popIn"
              duration="1000ms"
              delay="0s"
              direction="normal"
              timing="ease-in"
              iteration="1"
              fillMode="none">
              <Card elevation={20}>
                <CardMedia
                  component="img"
                  height="400"
                  image={image}
                  alt={name}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {name}
                  </Typography>
                  <Typography variant="body" color="text.secondary">
                    {description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    target="_blank"
                    href={url}
                    size="medium"
                    color={
                      theme.palette.mode === 'dark' ? 'primary' : 'secondary'
                    }>
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </MovingComponent>
          )
        })}
      </div>
    </Paper>
  )
}

export default PortfolioPage
