import {
  Box,
  FormControl,
  TextField,
  Paper,
  Button,
  Select,
  MenuItem,
} from '@mui/material'
import React, { useEffect } from 'react'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/system'
import axios from 'axios'
import { baseURL, config } from '../../api/api'

export const SkillEditForm = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { skillId } = useParams()

  const [name, setName] = React.useState('')
  const [image, setImage] = React.useState('')
  const [area, setArea] = React.useState('')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleImageChange = (event) => {
    setImage(event.target.value)
  }

  const fetchSkill = () => {
    axios
      .get(`${baseURL}/skill/${skillId}`)
      .then((res) => {
        setName(res.data.name)
        setImage(res.data.image)
        setArea(res.data.area)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchSkill()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (name && image && area) {
      axios
        .put(`${baseURL}/skill/${skillId}`, { name, image, area }, config)
        .then((res) => {
          navigate('/admin/skill')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  console.log(area)
  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '90%', mx: 'auto', mt: '30px', p: '10px' }}
        elevation={24}>
        <h2 style={{ textAlign: 'center' }}>Update a Skill</h2>
        <FormControl style={{ display: 'flex', gap: '20px', padding: '10px' }}>
          <TextField
            required
            fullWidth
            label="Name"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
          <TextField
            required
            id="image"
            label="image"
            multiline
            value={image}
            onChange={handleImageChange}
          />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={area}
            label="area"
            onChange={(event) => setArea(event.target.value)}>
            <MenuItem value={'front end'}>Front End</MenuItem>
            <MenuItem value={'back end'}>Back End</MenuItem>
            <MenuItem value={'database'}>Database</MenuItem>
            <MenuItem value={'version control'}>Version Control</MenuItem>
            <MenuItem value={'deployment'}>Deployment</MenuItem>
          </Select>
          <div>
            <Link to="/admin/skill">
              <Button
                sx={{ width: '5%', mr: '20px' }}
                variant="outlined"
                color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
                back
              </Button>
            </Link>
            <Button
              sx={{ width: '5%', display: 'inline' }}
              variant="outlined"
              onClick={handleSubmit}
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Edit
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Box>
  )
}
