import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Link } from 'react-router-dom'
import Loading from '../Loading/Loading'
import axios from 'axios'
import { baseURL, config } from '../../api/api'

export default function BlogTagTable() {
  const [blogTagList, setBlogTagList] = React.useState()

  const fetchBlogTagList = () => {
    axios
      .get(`${baseURL}/blogTag`)
      .then((res) => {
        setBlogTagList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchBlogTagList()
  }, [])

  const handleDelete = (blogTagId) => {
    axios
      .delete(`${baseURL}/blogTag/${blogTagId}`, config)
      .then((res) => {
        setBlogTagList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (!blogTagList) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return (
    <Paper elevation={24} sx={{ width: '90%', mx: 'auto', mt: '50px' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogTagList.map((row) => (
              <TableRow key={row._id}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">
                  <Link to={'edit/' + row._id} style={{ margin: '5px' }}>
                    edit
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleDelete(row._id)}
                    style={{ margin: '5px' }}>
                    delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
