import { Paper } from "@mui/material";
import React from "react";

export default function NotFound() {
  return (
    <Paper elevation={24} style={{ borderRadius: 0, minHeight: "90vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
        }}>
        <lottie-player
          src="https://assets9.lottiefiles.com/temp/lf20_USCruP.json"
          background="transparent"
          style={{ width: "400px", color: "red" }}
          speed="1"
          loop
          autoplay></lottie-player>
      </div>
    </Paper>
  );
}
