import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MovingComponent from 'react-moving-text'
import './SkillPage.scss'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { baseURL } from '../../api/api'
import Loading from '../../components/Loading/Loading'

function SkillPage() {
  const skillArea = [
    'front end',
    'back end',
    'database',
    'version control',
    'deployment',
  ]

  const [skillList, setSkillList] = React.useState()

  const fetchSkillList = () => {
    axios
      .get(`${baseURL}/skill`)
      .then((res) => {
        setSkillList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchSkillList()
  }, [])

  if (!skillList) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return (
    <Paper elevation={24} style={{ borderRadius: 0, minHeight: '90vh' }}>
      <Helmet>
        <title>Skill</title>
        <meta
          name="description"
          content={skillList.map((skill) => skill.name).join(', ')}
        />
      </Helmet>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '0',
          paddingTop: '30px',
          fontSize: '30px',
          textTransform: 'uppercase',
        }}>
        Skills
      </h2>
      <div style={{ padding: '10px 10vw' }}>
        {skillArea.map((skill) => (
          <Accordion key={skill} defaultExpanded={true} elevation={16}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography sx={{ textTransform: 'capitalize' }}>
                {skill}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cardContainer">
                {skillList
                  .filter((i) => i.area === skill)
                  .map((i) => (
                    <MovingComponent
                      key={i._id}
                      type="fadeInFromRight"
                      duration="1500ms"
                      delay="0s"
                      direction="normal"
                      timing="ease-in"
                      iteration="1"
                      fillMode="none">
                      <div className="card">
                        <span className="cardName">{i.name}</span>
                        <img
                          src={i.image}
                          alt={i.name}
                          style={{ width: '30px' }}
                        />
                      </div>
                    </MovingComponent>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Paper>
  )
}

export default SkillPage
