import { Button, Chip, Paper } from '@mui/material'
import React from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { useTheme } from '@mui/system'
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
import { baseURL } from '../../api/api'
import moment from 'moment'
import rehypeRaw from 'rehype-raw'
import CodeBlock from '../../utils/CodeBlock'
import Loading from '../../components/Loading/Loading'
import { Helmet } from 'react-helmet'

export default function PostPage() {
  const theme = useTheme()
  const location = useLocation()
  const { postId } = useParams()

  const [post, setPost] = React.useState()

  const fetchPost = () => {
    axios
      .get(`${baseURL}/blog/${postId}`)
      .then((res) => {
        setPost(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchPost()
  }, [])

  if (!post) {
    return (
      <Paper elevation={24} style={{ borderRadius: 0, minHeight: '100vh' }}>
        <Loading />
      </Paper>
    )
  }

  return (
    <Paper
      elevation={24}
      style={{
        borderRadius: 0,
        minHeight: '100vh',
        paddingTop: '50px',
      }}>
      <Helmet>
        <title>
          {post.title?.charAt(0).toUpperCase() + post.title?.slice(1)}
        </title>
        <meta name="description" content={post.content} />
      </Helmet>
      <Paper
        elevation={10}
        style={{
          width: '75vw',
          margin: 'auto',
          padding: '20px',
        }}>
        <h2>{post.title?.charAt(0).toUpperCase() + post.title?.slice(1)}</h2>
        <p>
          {moment(post.createdAt).format('DD/MM/YYYY')}
          <span style={{ marginLeft: '20px' }}>
            {Math.ceil(post.content.length / 200)}mins read
          </span>
        </p>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} components={CodeBlock}>
          {post.content}
        </ReactMarkdown>
        <div style={{ marginLeft: '10px', display: 'flex', gap: '10px' }}>
          {post.blogTag?.map((t) => (
            <Chip
              key={t._id}
              label={`#${t.name}`}
              style={{
                backgroundColor: '#d6d3cb',
                textTransform: 'uppercase',
              }}
            />
          ))}
        </div>
        {location.pathname.includes('view') ? (
          <Link to="/admin/blog">
            <Button
              sx={{ width: '5%', mt: '15px', mx: 'auto' }}
              variant="outlined"
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Back
            </Button>
          </Link>
        ) : (
          <Link to="/blog">
            <Button
              sx={{ width: '5%', mt: '15px', mx: 'auto' }}
              variant="outlined"
              color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
              Back
            </Button>
          </Link>
        )}
      </Paper>
    </Paper>
  )
}
