import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import CodeBlock from '../../utils/CodeBlock'

function BlogCard({ id, createdAt, title, content, tag }) {
  const theme = useTheme()
  return (
    <Card sx={{ minWidth: 275, width: '90vw', margin: 'auto' }} elevation={20}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.primary">
          {moment(createdAt).format('DD/MM/YYYY')}
          <span style={{ marginLeft: '20px' }}>
            {Math.ceil(content.length / 200)}mins read
          </span>
        </Typography>
        <ReactMarkdown ehypePlugins={[rehypeRaw]} components={CodeBlock}>
          {content.length > 100 ? `${content.slice(0, 200)}   ...` : content}
        </ReactMarkdown>
      </CardContent>
      <div style={{ marginLeft: '10px', display: 'flex', gap: '10px' }}>
        {tag.map((t) => (
          <Chip
            key={t._id}
            label={`#${t.name}`}
            style={{
              backgroundColor: '#d6d3cb',
              textTransform: 'uppercase',
            }}
          />
        ))}
      </div>
      <CardActions>
        <Link to={`/blog/${id}`}>
          <Button
            size="medium"
            color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>
            Learn More
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default BlogCard
